import { useEffect, useState } from 'react';
import DateTime from './header';

export default function StatsSlideshow({ top = 150 }) {
    const [iframe_width, set_iframe_width] = useState(0);
    const [side_width, set_side_width] = useState(0);

    const recalculate_aspect_ratio = () => {
        const window_height = window.innerHeight;
        const window_width = window.innerWidth;
        const new_iframe_width = ((window_height - top) * 16) / 9;
        const new_side_width = window_width - new_iframe_width;
        console.log(window_width, new_iframe_width, new_side_width);
        set_iframe_width(new_iframe_width);
        set_side_width(new_side_width);
    };

    useEffect(() => {
        recalculate_aspect_ratio();
        setTimeout(() => window.location.reload(), 3600000);
        window.addEventListener('resize', recalculate_aspect_ratio);

        return () => {
            window.removeEventListener('resize', recalculate_aspect_ratio);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div
            style={{
                background: 'black',
                '--side_top': `${top}px`,
                '--iframe_width': `${iframe_width}px`,
                '--side_width': `${side_width}px`,
            }}
        >
            <div className='stats_backdrop' />
            <div className='stats_top p-2 px-4'>
                <DateTime />
            </div>
            <div className='stats_box'>
                <iframe
                    src={`https://docs.google.com/presentation/d/e/2PACX-1vTgOVgmbMhBU8z6eel-Lc5RJgTJTtMApxgzRtZXiIXPUuZ8Tz5WeUX7HrX5Yts_DoO8mzryHOvZG0gH/embed?start=true&loop=true&delayms=30000&rm=minimal`}
                    frameborder='0'
                    allowfullscreen='true'
                    mozallowfullscreen='true'
                    webkitallowfullscreen='true'
                    title='CIT Stats'
                ></iframe>
            </div>
        </div>
    );
}
