import create from 'zustand';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle } from '@mdi/js';
import { useUserStore, DateFormat } from 'the-stack';
import { UploadProgress } from '../../Views/Uploads/AllUploads/UploadProgress';
import MyAccountPhoto from '../../Components/MyAccountPhoto';
export const useUploadStore = create((set, get) => ({
    state: {},
    refresh_number: 0,
    current_progress: [],
    uploads: [],
    loaded: false,
    uploadTableColumns: [
        {
            key: 'date_created',
            tdStyle: { width: '1%' },
            name: 'Date',
            sortEnable: 'DATE',
            td_className: 'align-middle nowrap',
            sortFormat: ({ row: { date_created } }) => date_created,
            display: ({ row: { date_created } }) => DateFormat({ enableTime: false, unixTime: date_created }),
        },
        {
            key: '_id',
            name: 'ID',
            tdStyle: { width: '1%' },
            td_className: 'align-middle nowrap',
            display: ({ row: { _id } }) => (
                <Link className='fw-bold text-decoration-underline' to={`/uploads/${_id}`}>
                    {_id}
                </Link>
            ),
        },
        {
            key: 'status',
            name: 'Status',
            tdStyle: { width: '1%' },
            sortEnable: 'STRING',
            td_className: 'align-middle nowrap',
        },
        {
            key: 'type',
            sortEnable: 'STRING',
            name: 'Type',
            td_className: 'align-middle nowrap',
        },
        {
            key: 'user',
            name: 'User',
            tdStyle: { width: '1%' },
            sortEnable: 'STRING',
            td_className: 'nowrap align-middle',
            string: ({ row: { user } }) => {
                const { full_name } = useUserStore.getState().UserDetail(user);
                return `${full_name}`;
            },
            display: ({ row: { user } }) => (
                <div className='d-flex justify-content-center'>{user > 0 && <MyAccountPhoto small user={useUserStore.getState().UserDetail(user)} />}</div>
            ),
        },
        {
            key: 'progress',
            name: '',
            tdStyle: { width: '30px' },
            td_className: 'nowrap align-middle p-0',
            sortFormat: () => 100,
            display: ({ row }) =>
                row.status === 'N/A' ? (
                    ''
                ) : row.status === 'Completed' ? (
                    <Icon path={mdiCheckboxMarkedCircle} color='var(--cit-green-50)' size='24px' />
                ) : (
                    <UploadProgress {...row} />
                ),
        },
    ],
    dispatch: (action) => {
        switch (action.type) {
            case 'set_uploads':
                set({ uploads: action.value, loaded: action.loaded });
                return;
            case 'refresh':
                set({ refresh_number: (get().refresh_number ?? 0) + 1 });
                return;
            case 'update_progress':
                const { upload_id, type, total_lines, count, failed, success } = action.message;

                const current_progress = (get().current_progress ?? []).filter((x) => x.upload_id !== upload_id && x.type !== type);

                set({
                    current_progress: [
                        ...current_progress,
                        {
                            type: type,
                            upload_id: upload_id,
                            total_lines: total_lines,
                            count: count,
                            failed: failed,
                            success: success,
                        },
                    ],
                });
                return;
            default:
                return;
        }
    },
}));
