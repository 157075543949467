import { PuffLoader } from 'the-stack';
import React from 'react';

export default function SuspensePuffLoader() {
    return (
        <div className='d-flex h-100 justify-content-center'>
            <div className='align-self-center'>
                <PuffLoader />
            </div>
        </div>
    );
}
