import {
    mdiTemperatureFahrenheit,
    mdiWeatherCloudy,
    mdiWeatherFog,
    mdiWeatherLightning,
    mdiWeatherNight,
    mdiWeatherNightPartlyCloudy,
    mdiWeatherPartlyCloudy,
    mdiWeatherRainy,
    mdiWeatherSnowyHeavy,
    mdiWeatherSunny,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useMemo } from 'react';

function get_icon(weathermap_icon) {
    switch (weathermap_icon) {
        case '01d':
            return mdiWeatherSunny;
        case '01n':
            return mdiWeatherNight;
        case '02d':
            return mdiWeatherPartlyCloudy;
        case '02n':
            return mdiWeatherNightPartlyCloudy;
        case '03d':
        case '03n':
        case '04d':
        case '04n':
            return mdiWeatherCloudy;
        case '09d':
        case '09n':
        case '10d':
        case '10n':
            return mdiWeatherRainy;
        case '11d':
        case '11n':
            return mdiWeatherLightning;
        case '13d':
        case '13n':
            return mdiWeatherSnowyHeavy;
        case '50d':
        case '50n':
            return mdiWeatherFog;
        default:
            return mdiWeatherSunny;
    }
}

export default function Weather({ weather = {} }) {
    const [current_icon, current_icon_name] = useMemo(
        () => (weather?.current ?? false ? [get_icon(weather.current.weather[0].icon), weather.current.weather[0].main] : [false, false]),
        [weather]
    );

    const [temp_farenheight] = useMemo(() => [((weather.current.temp * 9) / 5 - 459.67).toFixed(0)], [weather]);

    return (
        <div>
            <div className='d-flex gap-2 justify-content-around'>
                {current_icon === false ? <></> : <Icon path={current_icon} title={current_icon_name} size='3rem' color='#fff' className='align-self-center' />}
                <div className='align-self-center d-flex gap-1'>
                    <div className='align-self-center fs-1'>{temp_farenheight}</div>
                    <Icon path={mdiTemperatureFahrenheit} size='1.5rem' color='#fff' className='align-self-center' />
                </div>
            </div>
        </div>
    );
}
