import './index.css';
import './cit.css';
import App from './App/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Swal from 'sweetalert2';

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);

// This listens for the user to type a phrase, then shows a help box
const help_phrase = 'cit911';

var input_keys = '';

window.addEventListener('keyup', (event) => {
    input_keys = input_keys + event.key;
    if (input_keys === help_phrase) {
        // Show the help section
        // Context Menu
        input_keys = '';
        Swal.fire({
            title: '911 HELP',
            html: `<div class="text-start">
                <div class="mb-3">
                    Welcome to the diagnostic panel. Please don't click buttons here unless told to do so.
                </div>
                <div>
                    <button id="911btn_cache_bust" class="btn btn-outline-success">
                        Bust the Cache
                    </button>
                </div>
            </div>`,
            showCloseButton: true,
            showConfirmButton: false,
            didRender: () => {
                document.getElementById('911btn_cache_bust').addEventListener('click', () => {
                    localStorage.setItem('site_version', '911');
                    window.location.reload();
                });
            },
        });
    } else if (help_phrase.indexOf(input_keys) === 0) {
        return;
    } else if (help_phrase.indexOf(event.key) === 0) {
        input_keys = event.key;
    } else {
        input_keys = '';
    }
});

document.addEventListener('click', (e) => {
    try {
        if (e.target && (e.target?.className ?? '').indexOf('modal-backdrop') > -1) e.target.remove();
    } catch (error) {}
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
    // navigator.serviceWorker
    //     .register('/sw.js')
    //     .then((response) => {})
    //     .catch((error) => console.log(`Service Worker Registration Failed: ${error}`));

    // navigator.serviceWorker.ready.then((registration) => {
    //     if (window.location.hostname !== 'localhost') {
    //         fetch('/manifest.json')
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 const current_version = localStorage.getItem('site_version') ?? '';

    //                 if (data.version !== current_version) registration.active.postMessage({ event: 'CacheBustVersion', version: data.version });
    //             });
    //     }
    // });

    // navigator.serviceWorker.addEventListener('message', (event) => {
    //     // event is a MessageEvent object
    //     if (event.data.event === 'CacheBustVersion') {
    //         if (event.data.version?.length > 0) {
    //             localStorage.setItem('site_version', event.data.version);
    //             window.location.reload();
    //         } else {
    //             console.log('Cache Bust No Version Number');
    //         }
    //     }
    // });
}
