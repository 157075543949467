import 'react-vertical-timeline-component/style.min.css';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Home/View';
import SuspensePuffLoader from '../../Components/SuspensePuffLoader';
const EbayTransfer = React.lazy(() => import('../Ebay/Transfer/View'));
const BinSearch = React.lazy(() => import('../Ebay/Bin[id]/View'));
const EbayHome = React.lazy(() => import('../Ebay/Home/ListingsView'));
const EbayShip = React.lazy(() => import('../Ebay/Ship/Ship'));
const NewListing = React.lazy(() => import('../Ebay/NewListing/NewListing'));
const EbayReturnsView = React.lazy(() => import('../Ebay/Returns[returnId]/View'));
const EbayListing = React.lazy(() => import('../Ebay/Listing/View'));
const EbayReturns = React.lazy(() => import('../Ebay/Returns/View'));
const ShippingHome = React.lazy(() => import('../Shipping/Home/Home'));
const ShippingPack = React.lazy(() => import('../Shipping/Packing/Packing2'));
const ShippingPicking = React.lazy(() => import('../Shipping/Picking/View'));
const ShippingShip = React.lazy(() => import('../Shipping/Ship/Ship'));
const Returns = React.lazy(() => import('../Shipping/Returns/Returns'));
const Return = React.lazy(() => import('../Shipping/Returns/View'));
const SalesReport = React.lazy(() => import('../Accounting/SalesReport/View'));
const Payments = React.lazy(() => import('../Accounting/PaymentsAndCredits/View'));
const Payment = React.lazy(() => import('../Accounting/Payments/Payment'));
const Credit = React.lazy(() => import('../Accounting/Credits/Credit'));
const Unnapplied = React.lazy(() => import('../Accounting/Unapplied/View'));

const BarcodeInfo = React.lazy(() => import('../Inventory/BarcodeInfo/BarcodeInfo'));
const Run = React.lazy(() => import('../Inventory/Run/View'));
const InventoryEnteringView = React.lazy(() => import('../Inventory/Entering/View'));
const BinReview = React.lazy(() => import('../Inventory/BinReview/BinReview'));
const TrueUp = React.lazy(() => import('../Inventory/TrueUp/TrueUp'));
const Adjustment = React.lazy(() => import('../Inventory/Adjustment/View'));
const Adjustments = React.lazy(() => import('../Inventory/Adjustments/AdjustInventory'));
const AdjustmentHistory = React.lazy(() => import('../Inventory/Adjustments/AdjustmentHistory'));
const AssignBarcodes = React.lazy(() => import('../Inventory/AssignBarcodes/AssignBarcodes'));
const CutoverEntering = React.lazy(() => import('../Cutover/CutoverEntering'));
const InventoryHome = React.lazy(() => import('../Inventory/Home/View'));
const Missing = React.lazy(() => import('../Inventory/Missing/View'));
const DeviceInfo = React.lazy(() => import('../Inventory/DeviceInfo/DeviceInfo'));
const InventoryNewLine = React.lazy(() => import('../Inventory/NewLine/View'));
// const InventorySettings = React.lazy(() => import('../Inventory/Settings/View'));
const RecItemView = React.lazy(() => import('../Inventory/RecItem/View'));

const Roles = React.lazy(() => import('../UserManagement/Roles/Roles'));
const RolePermissions = React.lazy(() => import('../UserManagement/Roles/RolePermissions'));
const UserListView = React.lazy(() => import('../UserManagement/UserListView'));
const UserView = React.lazy(() => import('../UserManagement/UserView'));
const ModifyPermissions = React.lazy(() => import('../UserManagement/ModifyPermissionsView'));

const ReportView = React.lazy(() => import('../Reports/ReportView'));

const ShipmentView = React.lazy(() => import('../Receiving/ShipmentNew/View'));
const BarcodeLookup = React.lazy(() => import('../Receiving/BarcodeLookup/view'));
const TestingMetrics = React.lazy(() => import('../Receiving/TestingMetrics/View'));
const ReceiveHome = React.lazy(() => import('../Receiving/ReceiveHome/ReceiveHome'));
const ReceiveShipment = React.lazy(() => import('../Receiving/ReceiveShipment/ReceiveShipmentView'));
const ReceiveView = React.lazy(() => import('../Receiving/ReceiveProcesses/View'));
const POAudit = React.lazy(() => import('../Receiving/NetSuitePurchaseOrderAudit/View'));

const NewSalesInventory = React.lazy(() => import('../Sales/SalesInventory/NewSalesInventory'));
const SalesHome = React.lazy(() => import('../Sales/SalesHome/SalesHome'));
const AgingReport = React.lazy(() => import('../Sales/AgingReport/View'));
const Customer = React.lazy(() => import('../Sales/Customer/View2'));
const Orders = React.lazy(() => import('../Sales/Orders/View'));
const SalesOrder = React.lazy(() => import('../Sales/SalesOrder/View'));

const Email = React.lazy(() => import('../Alert/Email/View'));
const Subscription = React.lazy(() => import('../Alert/Subscription/View'));

const Uploads = React.lazy(() => import('../Uploads/AllUploads/View'));
const Upload = React.lazy(() => import('../Uploads/Upload/View'));

export default function Main(props) {
    const { user, user_update_image } = props;

    return (
        <div className={`bg-tan container-fluid`} style={{ maxWidth: 'calc(100vw - 220px)' }}>
            <div className='row h-100 align-content-start'>
                <Switch>
                    <Route path='/accounting/payments'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Payments />
                        </Suspense>
                    </Route>
                    <Route
                        path='/accounting/unapplied_report'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Unnapplied />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/uploads/new/:type'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Uploads type={props.match.params.type} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/uploads/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Upload upload_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/uploads'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Uploads />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/accounting/payment/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Payment payment_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/accounting/sales_report'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <SalesReport payment_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/accounting/credit/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Credit credit_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route path='/cutover/entering'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <CutoverEntering user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/ebay/adjustments'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Adjustments user={user} ebay />
                        </Suspense>
                    </Route>
                    <Route path='/ebay/listings'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <EbayHome />
                        </Suspense>
                    </Route>
                    <Route path='/ebay/ship'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <EbayShip user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/ebay/transfer'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <EbayTransfer />
                        </Suspense>
                    </Route>
                    <Route
                        path='/ebay/listing/new'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <NewListing />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/ebay/listing/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <EbayListing listing_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/ebay/returns/:returnId'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <EbayReturnsView return_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/ebay/returns/'
                        render={(props) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <EbayReturns />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/ebay/bin/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <BinSearch />
                            </Suspense>
                        )}
                    />
                    <Route path='/ebay'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <EbayHome />
                        </Suspense>
                    </Route>
                    <Route
                        path='/alert/email/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Email email_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/alert/subscription/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Subscription subscription_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    {/* <Route
                        path='/inventory/settings'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <InventorySettings />
                            </Suspense>
                        )}
                    /> */}
                    <Route
                        path='/inventory/adjustment/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Adjustment adjustment_id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route path='/inventory/adjustments/new'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Adjustments user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/adjustments'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <AdjustmentHistory alone={true} user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/assign-barcodes'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <AssignBarcodes user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/bin-review'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <BinReview />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/true-up'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <TrueUp />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/entering'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <InventoryEnteringView user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/missing'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Missing user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/inventory/run'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Run user={user} />
                        </Suspense>
                    </Route>
                    <Route
                        path='/inventory/rec/:id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <RecItemView id={props.match.params.id} />
                            </Suspense>
                        )}
                    />
                    <Route path='/inventory/device/new'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <InventoryNewLine />
                        </Suspense>
                    </Route>
                    <Route
                        path='/inventory/device/:device'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <DeviceInfo inv_id={props.match.params.device} user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/inventory/barcode/:barcode'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <BarcodeInfo user={user} barcode={props.match.params.barcode} />
                            </Suspense>
                        )}
                    ></Route>
                    <Route
                        path='/inventory/barcode'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <BarcodeInfo user={user} barcode={undefined} />
                            </Suspense>
                        )}
                    />
                    <Route path='/inventory'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <InventoryHome user={user} />
                        </Suspense>
                    </Route>
                    <Route
                        path='/receiving/receive/:shipment'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ReceiveView shipment_id={props.match.params.shipment} />
                            </Suspense>
                        )}
                    />
                    <Route path='/receiving/receive'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <ReceiveView />
                        </Suspense>
                    </Route>
                    <Route
                        path='/receiving/shipment-new/:shipment'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ShipmentView shipment_id={props.match.params.shipment} />
                            </Suspense>
                        )}
                    ></Route>
                    <Route
                        path='/receiving/shipment/:shipment'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ReceiveShipment shipment_id={props.match.params.shipment} />
                            </Suspense>
                        )}
                    ></Route>
                    <Route path='/receiving/ns_po_audit'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <POAudit />
                        </Suspense>
                    </Route>
                    <Route path='/receiving/lookup'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <BarcodeLookup mode='Lookup' />
                        </Suspense>
                    </Route>
                    <Route path='/receiving/testing'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <BarcodeLookup mode='Testing' />
                        </Suspense>
                    </Route>
                    <Route path='/receiving/rma'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <BarcodeLookup mode='RMA' />
                        </Suspense>
                    </Route>
                    <Route path='/receiving/testing-metrics'>
                        <Suspense fallback={<div>Loading...</div>}>
                            <TestingMetrics />
                        </Suspense>
                    </Route>
                    <Route path='/receiving'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <ReceiveHome />
                        </Suspense>
                    </Route>
                    <Route path='/sales/home'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <SalesHome user={user} />
                        </Suspense>
                    </Route>
                    <Route path='/sales/aging-report'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <AgingReport />
                        </Suspense>
                    </Route>
                    <Route
                        path='/sales/customer/:customer_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Customer user={user} user_id={props.match.params.customer_id} />
                            </Suspense>
                        )}
                    ></Route>
                    <Route path='/sales/payments'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Payments />
                        </Suspense>
                    </Route>
                    <Route path='/sales/inventory'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <NewSalesInventory user={user} />
                        </Suspense>
                    </Route>
                    <Route
                        path='/sales/orders/:view'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Orders user={user} default_view={props.match.params.view} />
                            </Suspense>
                        )}
                    />
                    <Route path='/sales/orders'>
                        <Suspense fallback={<SuspensePuffLoader />}>
                            <Orders user={user} default_view={'incoming'} />
                        </Suspense>
                    </Route>
                    <Route
                        path='/sales/:user_id/:order_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <SalesOrder user_id={props.match.params.user_id} order_id={props.match.params.order_id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/returns/0'
                        render={(props) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Returns user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/returns/:return_id'
                        render={(props) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Return user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/returns'
                        render={(props) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Returns user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/home/:view'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ShippingHome user={user} view={props.match.params.view ?? 'picking'} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/picking/:order_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ShippingPicking order_id={props.match.params.order_id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/picking'
                        render={(props) => (
                            <Suspense fallback={<div>Loading...</div>}>
                                <ShippingHome user={user} view={'picking'} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/pack/:order_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ShippingPack user={user} order_id={props.match.params.order_id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/shipping/ship/:order_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ShippingShip user={user} order_id={props.match.params.order_id} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/user/permissions'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ModifyPermissions user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/user/roles/:role_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <RolePermissions role_id={props.match.params.role_id} current_user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/user/roles'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <Roles />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/users/:user_id'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <UserView user_id={props.match.params.user_id} current_user={user} user_update_image={user_update_image} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/users'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <UserListView user={user} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path='/report/:report_name'
                        render={(props) => (
                            <Suspense fallback={<SuspensePuffLoader />}>
                                <ReportView user={user} report={props.match.params.report_name} />
                            </Suspense>
                        )}
                    />
                    <Route path='/' render={() => <Home />} />
                </Switch>
            </div>
        </div>
    );
}
