import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Background, Login, ServerAlert, useSession, useUserStore, VersionChecker, usePermission, ActivityModal, SiteContainer, useMqtt } from 'the-stack';
import Main from '../Views/Main/Main';
import StatsSlideshow from '../Views/StatsSlideshow/StatsSlideshow';
import { UploadManager } from './UploadManager/UploadManager';

export default function App() {
    const history = useHistory();

    const authenticated = useUserStore((state) => state.authenticated);
    const logout = useUserStore((state) => state.logout);
    const setUser = useUserStore((state) => state.setUser);
    const user = useUserStore((state) => state.user);

    const trackTimeEnabled = usePermission(355);
    const canLogin = usePermission(4);

    useSession();
    useMqtt();

    useEffect(() => {
        if (user.id > 0 && !canLogin) logout();
    }, [canLogin, logout, user]);

    // This updates the user's profile image
    const user_update_image = useCallback(
        (image, blur, user) => {
            if (authenticated.current !== 1) return;
            let newUser = { ...user };
            newUser.image = image;
            newUser.image_blur = blur;
            setUser(newUser);
        },
        [authenticated, setUser]
    );

    const stats_view = useMemo(() => history.location.pathname.indexOf('/stats') > -1, [history]);

    return stats_view ? (
        <Switch>
            <Route path='/stats/:size' render={(props) => <StatsSlideshow top={props.match.params.size} />} />
            <Route path='/stats' render={() => <StatsSlideshow top={150} />} />
        </Switch>
    ) : (
        <>
            <Background />
            {authenticated ? (
                <>
                    <SiteContainer site='Atlas'>
                        <Main user={user} user_update_image={user_update_image} logout={logout} />
                    </SiteContainer>
                    <UploadManager />
                    {trackTimeEnabled && <ActivityModal showDuringActivity={['63f3e30537a92dff4fe033f7', '63f3eb4837a92dff4fe033f9']} />}
                </>
            ) : (
                <Login />
            )}
            <VersionChecker />
            <ServerAlert />
        </>
    );
}
