import 'react-image-crop/dist/ReactCrop.css';

import React, { PureComponent } from 'react';

import { Axios } from 'the-stack';

import { Blurhash } from 'react-blurhash';
import ReactCrop from 'react-image-crop';
import axios from 'axios';
import { encode } from 'blurhash';

class MyAccountPhoto extends PureComponent {
    image_blob = null;

    state = {
        src: null,
        blurhash_new: '',
        crop: {
            unit: '%',
            width: 30,
            aspect: 1 / 1,
        },
        saving: false,
    };

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => this.setState({ src: reader.result }));
            reader.readAsDataURL(e.target.files[0]);
        }

        document.getElementById('file_photo').value = '';
    };

    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

        this.setState({
            blurhash_new: encode(ctx.getImageData(0, 0, canvas.width, canvas.height).data, canvas.width, canvas.height, 4, 4),
        });

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }

                blob.name = fileName;

                this.image_blob = blob;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    upload_photo = () => {
        if (this.state.croppedImageUrl) {
            this.setState(
                {
                    saving: true,
                },
                () => {
                    const session_id = localStorage.getItem('session_id');

                    let formData = new FormData();
                    formData.append('image', this.image_blob, 'photo.jpeg');
                    formData.append('session_id', session_id);
                    formData.append('for_user', this.props.user.username);
                    formData.append('blurhash', this.state.blurhash_new);

                    axios
                        .post('/api/user_photo', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                        .then((response) => {
                            if (this.props.current_user.username === this.props.user.username)
                                this.props.user_update_image(response.data.random, this.state.blurhash_new);
                            else this.props.set_user({ ...this.props.user, image: response.data.random, image_blur: this.state.blurhash_new });
                            this.setState({
                                croppedImageUrl: null,
                                src: null,
                                saving: false,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            );
        }
    };

    render() {
        const session_id = localStorage.getItem('session_id');
        const {
            change = false,
            medium = false,
            small = false,
            timeline = false,
            edit = false,
            user,
            blur_text = false,
            blur_text_animate = false,
            shadow = true,
        } = this.props;
        const { crop, croppedImageUrl, src } = this.state;

        const width = small ? 35 : timeline ? 40 : medium ? 50 : 100;
        const height = small ? 35 : timeline ? 40 : medium ? 50 : 100;
        const is_mobile = window.innerWidth < 992;

        return (
            <div className='align-self-center'>
                <div className={`d-flex${change ? ` mb-4 mb-lg-0 justify-content-center justify-content-lg-end` : ''}`}>
                    {change && (
                        <div className={`align-self-center text-center ms-2${change && edit && !is_mobile ? '' : ' d-none'}`}>
                            <input id='file_photo' className='d-none' type='file' accept='image/*' onChange={this.onSelectFile} />
                            <label id='btn_photo_change' className='btn btn-outline-secondary border-0' htmlFor='file_photo'>
                                {this.props.user.image === null ? 'Add' : 'Change'} Photo
                            </label>
                            <div className='break' />
                            {croppedImageUrl ? (
                                <button
                                    className='btn btn-success mt-1 mt-md-0'
                                    disabled={this.state.saving}
                                    onClick={() => {
                                        this.upload_photo();
                                    }}
                                >
                                    {this.state.saving ? 'Saving Photo' : 'Save Photo'}
                                </button>
                            ) : this.props.user.image === null ? (
                                <></>
                            ) : (
                                <button
                                    className='btn btn-outline-danger border-0'
                                    onClick={() => {
                                        Axios({ procedure: 'ATLAS_U_user_image', params: [null, null] }).then((e) => {
                                            this.props.user_update_image(null, null);
                                        });
                                    }}
                                >
                                    Remove Photo
                                </button>
                            )}
                            {croppedImageUrl && !this.state.saving && (
                                <button
                                    className='btn btn-outline-danger border-0 mt-1 mt-md-0'
                                    disabled={this.state.saving}
                                    onClick={() => {
                                        this.setState({
                                            src: null,
                                            croppedImageUrl: null,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    )}
                    <div className={`account_photo${shadow ? ' shadow' : ''}${small ? ' small' : medium ? ' medium' : timeline ? ' timeline' : ''}`}>
                        <Blurhash
                            hash={this.props.user.image_blur ?? 'UTQcn|of~qof?bfQRjfQayfQj[fQoffQM{ay'}
                            width={width}
                            height={height}
                            resolutionX={32}
                            resolutionY={32}
                            punch={1}
                        />
                        {blur_text !== false ? (
                            <div className={blur_text_animate ? 'animate' : ''}>{blur_text}</div>
                        ) : user.username === null ? (
                            <img alt='Profile' src={`/api/user_photo?session_id=${session_id}&photo=blank`} />
                        ) : (
                            <img
                                alt='Profile'
                                src={
                                    croppedImageUrl
                                        ? croppedImageUrl
                                        : `/api/user_photo?session_id=${session_id}&photo=${user?.username ?? 'blank'}&random=${user.image}`
                                }
                            />
                        )}
                    </div>
                    {change && (
                        <div className={`align-self-center ms-2${change && edit && is_mobile ? '' : ' d-none'}`}>
                            <input id='file_photo' className='d-none' type='file' accept='image/*' onChange={this.onSelectFile} />
                            <label id='btn_photo_change' className='btn btn-outline-secondary border-0' htmlFor='file_photo'>
                                {this.props.user.image === null ? 'Add' : 'Change'} Photo
                            </label>
                            <div className='break d-sm-none' />
                            {croppedImageUrl ? (
                                <button
                                    className='btn btn-success mt-1 mt-md-0'
                                    disabled={this.state.saving}
                                    onClick={() => {
                                        this.upload_photo();
                                    }}
                                >
                                    {this.state.saving ? 'Saving Photo' : 'Save Photo'}
                                </button>
                            ) : this.props.user.image === null ? (
                                <></>
                            ) : (
                                <button
                                    className='btn btn-outline-danger border-0'
                                    onClick={() => {
                                        Axios({ procedure: 'ATLAS_U_user_image', params: [null, null] }).then((e) => {
                                            this.props.user_update_image(null, null);
                                        });
                                    }}
                                >
                                    Remove Photo
                                </button>
                            )}
                            {croppedImageUrl && !this.state.saving && (
                                <button
                                    className='btn btn-outline-danger border-0 mt-1 mt-md-0'
                                    disabled={this.state.saving}
                                    onClick={() => {
                                        this.setState({
                                            src: null,
                                            croppedImageUrl: null,
                                        });
                                    }}
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    )}
                </div>
                {src && !this.state.saving && (
                    <div className='text-center mb-3'>
                        <div className='text-start text-lg-end mb-2'>Crop Image to Fit</div>
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            circularCrop
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default MyAccountPhoto;
