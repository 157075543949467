import { Cell, Pie, PieChart } from 'recharts';
import { useMemo } from 'react';
import { useUploadStore } from '../../../App/UploadManager/Store';

export const UploadProgress = ({ type, upload_id }) => {
    const current_progress = useUploadStore((state) => state.current_progress);
    const {
        upload_id: no_progress = false,
        total_lines = 0,
        failed = 0,
        success = 0,
    } = useMemo(() => current_progress.find((x) => x.type === type && x.upload_id === upload_id) ?? {}, [upload_id, type, current_progress]);

    return (
        <div className='d-flex justify-content-center'>
            <PieChart width={24} height={24}>
                <Pie
                    animationBegin={3}
                    data={[
                        {
                            progress: total_lines === 0 ? 1 : total_lines,
                        },
                    ]}
                    dataKey='progress'
                    cx='50%'
                    cy='50%'
                    outerRadius={11}
                    innerRadius={11}
                    startAngle={90}
                    endAngle={-270}
                >
                    <Cell stroke={no_progress === false ? '#ccc' : 'var(--cit-green-50)'} fill={no_progress === false ? '#ccc' : 'var(--cit-green-50)'} />
                </Pie>
                <Pie
                    animationBegin={3}
                    data={[
                        {
                            progress: success,
                        },
                        {
                            progress: failed,
                        },
                        {
                            progress: total_lines === 0 ? 1 : total_lines - success - failed,
                        },
                    ]}
                    dataKey='progress'
                    cx='50%'
                    cy='50%'
                    outerRadius={10}
                    startAngle={90}
                    endAngle={-270}
                >
                    <Cell stroke={no_progress === false ? '#ccc' : 'var(--cit-green-50)'} fill={no_progress === false ? '#ccc' : 'var(--cit-green-50)'} />
                    <Cell stroke={no_progress === false ? '#ccc' : 'var(--bs-danger)'} fill={no_progress === false ? '#ccc' : 'var(--bs-danger)'} />
                    <Cell stroke='transparent' fill='transparent' />
                </Pie>
            </PieChart>
        </div>
    );
};
