import { useEffect, useReducer, useRef } from 'react';
import deepEqual from 'deep-equal';
import { useNonInitialEffect, useIsMounted, Axios, SwalToast as Toast } from 'the-stack';
import { useCallback } from 'react';

const DeepEqual = require('deep-equal');

export const useAxiosV2 = (call = '', params = [], options = {}) => {
    const mounted = useIsMounted();
    const ref_result_id = useRef(0);
    const ref_params_version = useRef(0);
    const ref_requesting = useRef(false);
    const ref_perm = useRef(options.permission ?? true);
    const ref_clear = useRef([]);
    const ref_network_error = useRef(false);
    const ref_axios_params = useRef(params);

    const [axios_result, axios_result_dispatch] = useReducer(result_reducer, { loaded: false, data: options.default_value ?? {} });
    const [opt_d, set_opt_d] = useReducer((state, action) => action, options.display_loaded);
    const [axios_timeout, set_axios_timeout] = useReducer((state, action) => action, undefined);
    const get_axios_result = (notify = false) => {
        if (!mounted()) return;
        if (ref_requesting.current) return;
        ref_requesting.current = true;

        if (!ref_perm.current || !(options?.params_valid ?? (() => true))(ref_axios_params.current)) {
            axios_result_dispatch({
                type: 'no_permission',
                data: options.default_value ?? {},
                loaded: opt_d ?? 0,
            });
            ref_requesting.current = false;
            return;
        }

        return new Promise((resolve, reject) => {
            const ref_result_id_number = ref_result_id.current + 1;
            const params_version = ref_params_version.current;
            const set_params = JSON.parse(JSON.stringify(ref_axios_params.current));
            ref_result_id.current = ref_result_id_number;
            Axios({ procedure: call, params: set_params })
                .then((data) => {
                    if (!mounted()) return null;
                    ref_network_error.current = false;

                    if (
                        ref_result_id_number === ref_result_id.current &&
                        ref_params_version.current === params_version &&
                        deepEqual(set_params, ref_axios_params.current)
                    ) {
                        if (((options.list ?? false) && (data?.data ?? [])[0]) || (!(options.list ?? false) && ((data?.data ?? [])[0] ?? [])[0])) {
                            axios_result_dispatch({
                                type: 'set',
                                data: options.list ?? false ? [...data.data[0]] : { ...data.data[0][0] },
                            });
                        } else {
                            axios_result_dispatch({
                                type: 'null_result',
                                data: options.default_value ?? {},
                            });
                        }
                        return true;
                    } else {
                        // parameters changed during request, re-request data
                        ref_requesting.current = false;
                        get_axios_result();
                        return null;
                    }
                })
                .then((result) => {
                    const lang = localStorage.getItem('language') ?? 'en';
                    if (result === null) return;
                    if (result === true && notify === true && options.name !== false)
                        Toast('success', `${(options.name ?? '').length === 0 ? '' : `${options.name} `}${lang === 'es' ? 'Actualizado' : 'Refreshed'}`);
                    ref_requesting.current = false;
                    resolve(result);
                })
                .catch((error) => {
                    console.log(error);

                    ref_requesting.current = false;
                    if (error.message === 'Network Error' && !ref_network_error.current) {
                        ref_network_error.current = true;
                        setTimeout(
                            () =>
                                get_axios_result(notify)
                                    .then((result) => resolve(result))
                                    .catch((result) => reject(result)),
                            5000
                        );

                        return;
                    }

                    return reject(error);
                });
        });
    };

    const modify_axios_result = useCallback(
        ({ search = '', value = undefined, field = 'id', upsert = false, remove = false, row = false }) => {
            if (value === undefined) return;
            if ((options.list ?? false) === true && (typeof field !== 'string' || field.length === 0)) return;
            if (ref_requesting.current)
                return setTimeout(() => modify_axios_result({ search: search, value: value, field: field, upsert: upsert, remove: remove, row: row }), 250);

            axios_result_dispatch({
                type: 'modify',
                list: options.list ?? false,
                search: search,
                field: field,
                value: value,
                upsert: upsert,
                remove: remove,
                row: row,
            });
        },
        [options.list]
    );

    useNonInitialEffect(() => {
        let interval;
        if (mounted()) {
            axios_result_dispatch({
                type: 'initial_setup',
                data: options.default_value ?? {},
            });
            ref_perm.current = options.permission ?? true;
            set_opt_d(options.display_loaded);
            get_axios_result();
            clearInterval(axios_timeout);
            ref_clear.current = ref_clear.current.filter((i) => i !== axios_timeout);
            interval = (options.refresh ?? 60000) === false ? 0 : setInterval(get_axios_result, options.refresh ?? 60000);
            ref_clear.current = [...ref_clear.current, interval];
            set_axios_timeout(interval);
        }
        // ! DO NOT REMOVE THIS LINE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref_axios_params.current, options.refresh ?? 60000, options.permission ?? true, options.display_loaded ?? 0]);

    useNonInitialEffect(() => {
        if (mounted()) {
            if (DeepEqual(ref_axios_params.current, JSON.parse(JSON.stringify(params)))) return;
            ref_params_version.current = ref_params_version.current + 1;
            clearInterval(axios_timeout);
            ref_clear.current = ref_clear.current.filter((i) => i !== axios_timeout);
            ref_axios_params.current = JSON.parse(JSON.stringify(params));
        }
        // ! DO NOT REMOVE THIS LINE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
        let interval;
        if (mounted()) {
            get_axios_result();
            clearInterval(axios_timeout);
            ref_clear.current = ref_clear.current.filter((i) => i !== axios_timeout);
            interval = (options.refresh ?? 60000) === false ? 0 : setInterval(get_axios_result, options.refresh ?? 60000);
            ref_clear.current = [...ref_clear.current, interval];
            set_axios_timeout(interval);
        }

        return () => {
            clearInterval(interval);
            clearInterval(axios_timeout);
            ref_clear.current.forEach((item) => {
                if (item !== interval && item !== axios_timeout) clearInterval(item);
            });
            ref_clear.current = [];
        };
        // ! DO NOT REMOVE THIS LINE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        refresh: (quiet = false) => get_axios_result(!quiet),
        loaded: axios_result.loaded,
        data: axios_result.data,
        modify: modify_axios_result,
    };
};

function result_reducer(state, action) {
    switch (action.type) {
        case 'set':
            return {
                loaded: true,
                data: action.data,
            };
        case 'modify':
            const { list, search, field, value, upsert = false, row = false } = action;
            if ((list ?? false) === false) {
                // Update Key in Array
                state.data[search] = value;
            } else {
                // Update List based upon search
                let updated = false;
                state.data = state.data.map((item) => {
                    if (item[field] === search) {
                        updated = true;
                        if (value === false && row !== false) return row;
                        item = value;
                    }
                    return item;
                });
                if (updated === false && upsert) state.data.push(row === false ? value : row);
            }
            return { ...state };
        case 'null_result':
        case 'no_permission':
        case 'initial_setup':
        default:
            return {
                loaded: (action.loaded ?? 0) === 1 ? true : false,
                data: action.data,
            };
    }
}
