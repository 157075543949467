import { useCallback, useRef } from 'react';
import { useMqttListener, useUserStore, useIsMounted } from 'the-stack';
import { useAxiosV2 } from '../../Components/useAxios';
import { useEffect } from 'react';
import { useUploadStore } from './Store';

export function UploadManager() {
    const checkPermission = useUserStore((state) => state.checkPermission);
    const ref_refresh = useRef(0);
    const dispatch = useUploadStore((state) => state.dispatch);
    const refresh_number = useUploadStore((state) => state.refresh_number);
    const permission_view_uploads = checkPermission(296);
    const mounted = useIsMounted();
    const { data, loaded, refresh } = useAxiosV2('UPL_G_uploads', [], {
        list: true,
        default_value: [],
        permission: permission_view_uploads,
    });

    useEffect(() => {
        if (mounted())
            dispatch({
                type: 'set_uploads',
                value: data,
                loaded: loaded,
            });
        //eslint-disable-next-line
    }, [loaded, data]);

    useEffect(() => {
        if (refresh_number !== ref_refresh.current) {
            ref_refresh.current = refresh_number;
            refresh(true);
        }
        //eslint-disable-next-line
    }, [refresh_number]);

    const mqttCallback = useCallback(
        ({ message, topic }) => {
            if (/^(atlas|tech|crew|cportal).*\/upload\/new$/.test(topic)) {
                return refresh(true);
            }
            if (/^(atlas|tech|crew|cportal).*\/upload\/.*\/\d*$/.test(topic)) {
                return dispatch({
                    type: 'update_progress',
                    message: message,
                });
            }
        },
        [refresh, dispatch]
    );
    useMqttListener({ qualifier: `%host%%environment%/upload/#`, callback: mqttCallback });
    return <></>;
}
