import React, { Suspense, useReducer, useEffect, useMemo, useRef } from 'react';
import { useUserStore, Page } from 'the-stack';

const CITFYI = React.lazy(() => import('./Slack/CITFYI'));

export default function Home() {
    const ref_interval = useRef();

    const { first_name } = useUserStore((state) => state.user);
    const [{ hours = false, minutes = false }, dispatch_time] = useReducer((state, action) => {
        const new_time = new Date();
        if (new_time.getHours() !== state.hours || new_time.getMinutes() !== state.minutes)
            return {
                hours: new_time.getHours(),
                minutes: new_time.getMinutes(),
            };
        return state;
    }, {});

    const greeting = useMemo(
        () => (hours === false ? '' : `Good ${hours < 12 ? 'Morning' : hours < 17 ? 'Afternoon' : hours < 20 ? 'Evening' : 'Night'} ${first_name}`),
        [hours, first_name]
    );

    useEffect(() => {
        dispatch_time();
        ref_interval.current = setInterval(dispatch_time, 1000);
        return () => {
            clearInterval(ref_interval.current);
        };
    }, []);
    return (
        <Page headerStyle={{ backgroundColor: 'transparent' }} style={{ backgroundColor: '#15283366' }}>
            <div className='d-flex justify-content-center' style={{ height: '60vh' }}>
                <div className='align-self-center text-white text-center'>
                    <div style={{ fontSize: '8rem', lineHeight: '7rem' }}>
                        {hours - (hours > 12 ? 12 : 0)}:{minutes < 10 ? 0 : ''}
                        {minutes}
                    </div>
                    <div style={{ fontSize: '3rem' }}>{greeting}</div>
                </div>
            </div>
            <Suspense fallback={<></>}>
                <div className='row'>
                    <div className='col-8 offset-2 align-self-center'>
                        {' '}
                        <CITFYI />
                    </div>
                </div>
            </Suspense>
        </Page>
    );
}
